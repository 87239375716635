.client-portal-config-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
  
    .configurations-table-section {
        margin-top: 3rem;
        margin-bottom: 2rem;
    
        .MuiTableContainer-root {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }
    
        .MuiTableHead-root {
          background-color: #f5f5f5;
          
          .MuiTableCell-head {
            font-weight: 600;
          }
        }
    
        .MuiTableCell-root {
          padding: 1rem;
        }
      }
    .select-client {
      margin-bottom: 2rem;
    }
  
    .module-config-section {
      margin-top: 2rem;
      background-color: #fff;
      padding: 1.5rem;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
      .module-config-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid #eee;
  
        &:last-child {
          border-bottom: none;
        }
  
        .module-controls {
          display: flex;
          gap: 1rem;
          align-items: center;
        }
      }
    }
  
    .theme-section {
      margin-top: 2rem;
      background-color: #fff;
      padding: 1.5rem;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
      .color-picker {
        margin-bottom: 1rem;
  
        input[type="color"] {
          padding: 0;
          width: 100%;
          height: 40px;
        }
      }
  
      .upload-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        background-color: #2196f3;
        color: #fff;
        padding: 8px;
        border: none;
        border-radius: 4px;
        margin-top: 1rem;
  
        &:hover {
          background-color: #1565c0;
        }
  
        input[type="file"] {
          display: none;
        }
      }
    }
  
    .form-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
  
      .field-container {
        flex-basis: calc(50% - 0.5rem);
        margin-bottom: 1rem;
        box-sizing: border-box;
  
        &.full-width {
          flex-basis: 100%;
        }
      }
    }
  
    .save-button {
      margin-top: 2rem;
      width: 100%;
      padding: 1rem;
    }
  
    // Estado de carga
    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
    }
  
    // Estilos para mostrar el logo seleccionado
    .logo-preview {
      margin-top: 1rem;
      max-width: 200px;
      max-height: 100px;
      object-fit: contain;
      border: 1px solid #eee;
      border-radius: 4px;
      padding: 0.5rem;
    }
  
    // Estilos para mensajes de error o advertencia
    .error-message {
      color: #d32f2f;
      margin: 0.5rem 0;
      font-size: 0.875rem;
    }
  
    .success-message {
      color: #2e7d32;
      margin: 0.5rem 0;
      font-size: 0.875rem;
    }
  
    // Responsive
    @media (max-width: 600px) {
      padding: 1rem;
  
      .field-container {
        flex-basis: 100% !important;
      }
  
      .module-config-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
  
        .module-controls {
          width: 100%;
          justify-content: flex-start;
        }
      }
    }
  }