.add-client-user-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
  
    .addButton {
      margin-bottom: 20px;
    }
  
    .form-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
  
      .field-container {
        flex-basis: calc(50% - 0.5rem);
        margin-bottom: 1rem;
        box-sizing: border-box;
      }
  
      .full-width {
        flex-basis: 100%;
      }
    }
  
    .module-config {
      background-color: #f5f5f5;
      padding: 1rem;
      border-radius: 4px;
      margin-bottom: 1rem;
  
      .module-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
      }
  
      .module-controls {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    }
  
    .theme-container {
      margin-top: 2rem;
  
      .color-picker {
        .MuiTextField-root {
          width: 100%;
        }
      }
  
      .upload-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        background-color: #2196f3;
        color: #fff;
        padding: 8px;
        border: none;
        border-radius: 4px;
  
        &:hover {
          background-color: #1565c0;
        }
  
        input[type="file"] {
          display: none;
        }
      }
    }
  
    .small {
      height: 50px;
      font-size: 15px;
    }
  
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 2rem;
    }
  }