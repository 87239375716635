.datatableTitle {
  color: #4678c3;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  padding: 20px 0 20px 0;
}

.tableContainer {
  padding: 0 50px 0 50px;
  .table {
    background-color: #fff;
    margin-bottom: 20px;
  }
  .table .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: #fff;
    }
    &:nth-of-type(even) {
      background-color: rgba(234, 239, 242, 0.5);
    }
  }
  .tableControlsRow {
    display: flex;
    gap: 10px; /* Ajusta el espacio entre los botones según tus preferencias */
    padding: 20px 0 20px 0;
    .downloadButton {
      button {
        border-radius: 4px;
        border: 1px solid #33a186;
        background: #33a186;
        color: white;
        padding: 8px 16px;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.7; // Puedes ajustar la opacidad según tus preferencias
        }

        &:hover:not(:disabled) {
          background: darken(
            #33a186,
            10%
          ); // Oscurece el color al pasar el mouse
        }
      }
    }
    .dateFilterDropdown {
      margin-left: 50px;
      label {
        color: #7f8489;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.4px; /* 160% */
        margin-right: 5px;
      }
      select {
        flex-shrink: 0;
        border: 1px solid #d9d9d9;
        background: #fff;
        padding: 5px 10px; // Ajusta el padding según tus necesidades
        border-radius: 4px;
      }
    }
  }
  .downloadedRow {
    margin-left: 5px; /* Iguala el espacio del borde izquierdo en notDownloadedRow */
  }

  .notDownloadedRow {
    font-weight: bold;
    border-left: 5px solid #1976d2; /* Raya azul a la izquierda */
  }
}

.image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.checkbox-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.status {
  padding: 5px;
  border-radius: 5px;

  &.Approved {
    color: green;
    background-color: rgba(0, 128, 0, 0.151);
  }
  &.Pending {
    color: goldenrod;
    background-color: rgba(189, 189, 3, 0.103);
  }
}
.cellAction {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: white;
  border-color: darkblue;
  a {
    text-decoration: none;
  }
  .viewButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: #fff;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
    background-color: rgba(122, 164, 192, 1);
  }
  .viewButtonSingle {
    padding: 2px 30px;
    border-radius: 5px;
    color: #fff;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
    background-color: rgba(70, 120, 195, 1);
  }
  .editButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: #fff;
    border: 1px dotted rgba(0, 128, 0, 0.596); /* Color de borde verde */
    cursor: pointer;
    background-color: #33a186; /* Color de fondo verde */
  }
  .deleteIcon {
    cursor: pointer;
    margin: 0 5px;
    color: #d03f3f; // Puedes ajustar el color según tu preferencia
    &:hover {
      color: #8e1919; // Cambia el color al pasar el mouse por encima
    }
  }
  .deleteButton {
    padding: 2px 5px;
    border-radius: 5px;
    color: crimson;
    border: 1px dotted rgba(220, 20, 60, 0.6);
    cursor: pointer;
  }
}

.searchBar,
.filterBarWrapper {
  display: flex;
  flex-wrap: wrap;
}

.filterBarWrapper > .filterBar {
  flex: 1;
  margin-right: 10px;
}

.filterBarWrapper > .filterBar:last-child {
  margin-right: 0;
}

.filterBar input {
  margin-bottom: 10px;
  width: calc(100% - 20px); /* Ajusta el ancho según tus necesidades */
  padding: 5px 10px;
  border: 1px solid #ccc; /* Puedes personalizar el color del borde */
  border-radius: 30px;
}

.filterBar input::placeholder {
  text-align: center;
}

.rightActions {
  display: flex;
  justify-content: flex-end; /* Align to the right */
}
.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 300px; // Ajusta esto según tus necesidades
  .searchInput {
    padding-right: 30px; // Espacio para el ícono
    width: 90%;
    border-radius: 30px;
    padding: 5px;
  }

  .searchIcon {
    position: absolute;
    right: 5px; // Alineado a la derecha
    cursor: pointer;
    color: rgba(70, 120, 195, 1); // El color que mencionaste anteriormente
  }
}
.pagination-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;

  .pagination-button {
    padding: 5px 10px;
    background-color: transparent;
    border: 2px solid rgba(70, 120, 195, 1);
    transition: background-color 0.3s;
    cursor: pointer;

    &.active-page {
      background-color: rgba(70, 120, 195, 1);
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:hover:not(:disabled) {
      background-color: rgba(70, 120, 195, 0.7);
      color: white;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  padding: 10px;
}

.pagination-button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f0f0f0;
  border-color: #ccc;
}

.pagination-button:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

.pagination-info {
  font-size: 14px;
  color: #666;
}
.loadMoreContainer {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.loading {
  text-align: center;
  padding: 10px;
  color: #666;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0;
}

.btn-page {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}

.btn-page:disabled {
  background: #f5f5f5;
  cursor: not-allowed;
}

.page-info {
  font-size: 0.9rem;
  color: #666;
}