.new-filter-bar {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 25px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  }
  
  .filter-controls {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .filter-row {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .filter-item {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin: 5px;

  }
  
  .filter-item label {
    font-size: 14px;
    font-weight: 600;
    color: #444;
    margin-bottom: 2px;
  }
  
  .filter-input {
    padding: 7px;
    border: 1px solid #ddd;
    margin: 5px;
    border-radius: 6px;
    font-size: 14px;
    transition: all 0.2s ease;
    background-color: white;
  }
  
  .filter-input:focus {
    border-color: #1976d2;
    outline: none;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
  }
  
  /* Estilos mejorados para el rango de fechas */
  .date-range-container {
    background-color: white;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.08);
    margin-top: 10px;
  }
  
  .date-range-title {
    font-size: 15px;
    font-weight: 600;
    color: #333;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .date-range-title svg {
    color: #1976d2;
  }
  
  .date-range-filters {
    display: flex;
    gap: 15px;
  }
  
  .date-range-filters .filter-item {
    flex: 1;
    margin-bottom: 0;
  }
  
  .date-range-filters label {
    color: #555;
  }
  
  .date-input {
    background-color: white;
    border: 1px solid #e0e0e0;
    transition: all 0.2s ease;
  }
  
  .date-input:hover {
    border-color: #bbbbbb;
  }
  
  .date-input:focus {
    border-color: #1976d2;
    box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.15);
  }
  
  .date-input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    color: #666;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .date-input::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
  }
  
  /* Estilos mejorados para los botones */
  .filter-actions {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #eaeaea;
  }
  
  .search-button, .clear-button {
    padding: 12px 24px;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .search-button {
    background-color: #1976d2;
    color: white;
  }
  
  .search-button:hover {
    background-color: #1565c0;
    box-shadow: 0 3px 6px rgba(0,0,0,0.15);
    transform: translateY(-1px);
  }
  
  .clear-button {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .clear-button:hover {
    background-color: #e8e8e8;
    box-shadow: 0 3px 6px rgba(0,0,0,0.08);
  }
  
  .search-button:disabled, .clear-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  .search-button:active, .clear-button:active {
    transform: translateY(1px);
    box-shadow: none;
  }
  
  /* Estilos responsivos */
  @media (min-width: 768px) {
    .filter-controls {
      flex-direction: column;
    }
    
    .filter-row {
      flex-direction: row;
    }
    
    .filter-item {
      flex-basis: calc(33.333% - 10px);
    }
  }
  
  @media (min-width: 1200px) {
    .filter-controls {
      gap: 25px;
    }
    
    .filter-row {
      flex-wrap: nowrap;
    }
    
    .filter-item {
      flex: 1;
    }
    
    .date-range-container {
      margin-top: 5px;
    }
  }
  
  @media (max-width: 767px) {
    .filter-row {
      flex-direction: column;
    }
    
    .date-range-filters {
      flex-direction: column;
      gap: 15px;
    }
    
    .filter-actions {
      flex-direction: column;
      align-items: stretch;
    }
    
    .search-button, .clear-button {
      width: 100%;
    }
    
    .date-range-container {
      padding: 12px;
    }
  }